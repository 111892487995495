import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const lensesDownload = () => {
  const data = useStaticQuery(graphql`
    query LensesTrial {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "DownloadTrialPage" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=')

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1]
        }
      }
    }
    var id = getUrlParameter('id')
    document.querySelector<HTMLInputElement>('#dl_id').defaultValue = id
    if (!id) {
      document.querySelector<HTMLButtonElement>('.btn').disabled = true
    }
  }, [])

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="pt-5 mt-5 pb-5">
        <div className="container-1">
          <div className="row mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a href="/start/" className="title_text">
                  DOWNLOADS
                </a>
              </p>
              <h1>Download Lenses Trial</h1>
              <div className="pr-5">
                <p className="hero-description-dark pr-5">
                  Lenses Enterprise Edition Archive & Key to connect your
                  cluster
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="se-section" style={{ border: '0px' }}>
        <div className="container-1">
          <div className="row" style={{ height: '370px' }}>
            <div
              className="col-md-12"
              style={{ height: '360px', overflow: 'auto' }}
              id="scrollerCa"
            >
              <h4>Software Evaluation License Agreement</h4>
              <p>
                This Software Evaluation License agreement
                (&ldquo;Agreement&rdquo;) is a legal agreement between you
                (either an individual or an entity) signing up to use the
                Product (The &ldquo;Licensee&rdquo;) and Lenses.io, Ltd. a
                company organised and existing in the United Kingdom, with a
                registered address at C/O Corporation Service Company (Uk)
                Limited, 5 Churchill Place, 10th Floor, London, E14 5HU, United
                Kingdom (The &ldquo;Licensor&rdquo;) regarding software you have
                requested to download (the &ldquo;Product&rdquo;). The purpose
                of this Agreement is to facilitate evaluation of Licensor’s
                Software (&ldquo;Product&rdquo;), proof of concept and other
                such demonstratives displays of the Product
              </p>
              <p>
                BY USING THE PRODUCT OR BY REGISTERING FOR THE PRODUCT OR BY
                CLICKING ON THE CHECKBOX THAT DEMONSTRATES ACCEPTANCE OF THIS
                AGREEMENT, THE LICENSEE REPRESENTS, WARRANTS, AND AGREES THAT
                HAS READ, UNDERSTOOD, AND AGREE TO BE BOUND TO THE TERMS OF THIS
                AGREEMENT. IF YOU ARE AN INDIVIDUAL AGREEING TO THE TERMS OF
                THIS AGREEMENT ON BEHALF OF AN ENTITY, SUCH AS YOUR EMPLOYER,
                YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT
                ENTITY AND &ldquo;LICENSEE&rdquo; SHALL REFER HEREIN TO SUCH
                ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT
                AGREE WITH THE TERMS OF THIS AGREEMENT, YOU MUST NOT ACCEPT THIS
                AGREEMENT AND MAY NOT DOWNLOAD OR USE THE PRODUCT.
              </p>
              <p>
                <b>1. DEFINITIONS</b>
                <br />
                1.1 &ldquo;Agreement&rdquo; means this License Agreement.
                <br /> 1.2 &ldquo;Commencement Date&rdquo; means the date you
                have registered for the software.
                <br /> 1.3 &ldquo;Confidential Information&rdquo; means
                information that: (a) is by its nature confidential; (b) is
                designated in writing by Licensor as confidential; (c) the
                Licensee knows or reasonably ought to know is confidential; (d)
                Information comprised in or relating to any Intellectual
                Property Rights of Licensor;
                <br /> 1.4 &ldquo;Product&rdquo; means the Proprietary Software
                Products provided by the Licensor
                <br /> 1.5 &ldquo;Intellectual Property Rights&rdquo; means all
                rights in and to any copyright, trademark, trading name, design,
                patent, know how (trade secrets) and all other rights resulting
                from intellectual activity in the industrial, scientific,
                literary or artistic field and any application or right to apply
                for registration of any of these rights and any right to protect
                or enforce any of these rights, as further specified in clause
                5.
                <br /> 1.6 &ldquo;Party&rdquo; means a person or business entity
                who has executed this Agreement
                <br /> 1.7 &ldquo;Technical Data&rdquo; means technical
                information regarding the Licensee’s, and the Licensee’s use of,
                devices, systems, applications and software.
                <br /> 1.8 &ldquo;Evaluation Term&rdquo; means thirty (30) days
                from the date of download by the Licensee or such other period
                specified in writing by the Licensor.
                <br /> 1.9 &ldquo;License Key&rdquo; means the key that is used
                to activate the Product for use by the Licensee.
                <br /> 1.10 &ldquo;Site&rdquo; means lenses.io
              </p>
              <p>
                <b>2. LICENSE GRANT</b>
                <br />
                2.1 Subject to the Agreement and provided that the Licensee has
                a valid License Key, the Licensor grants to the Licensee the
                revocable, non-exclusive, non-transferable, and
                non-sublicensable license for the Evaluation Term to use, only
                on servers owned or controlled by the Licensee, the Product for
                the specific purpose specified in this Agreement, subject to the
                terms and conditions set out in this Agreement.
                <br /> 2.2 In relation to the scope of use: (a) The Licensee may
                make backup reasonable amounts of copies of the Product for its
                lawful use. The Licensee shall record the number and location of
                all copies and take steps to prevent unauthorised use. (b)
                Except as expressly permitted in this clause 2, the Licensee has
                no right, and shall not permit any third party, to copy, adapt,
                reverse engineer, decompile, disassemble, modify or adapt the
                Product.
                <br /> 2.3 The Licensee shall not, without the prior written
                consent of the Licensor; (a) Sub-license, assign or novate the
                benefit or burden of this license in whole or in part; (b) Allow
                the Product to become the subject of any charge, lien or
                encumbrance; and (c) Deal in any other manner with any or all of
                its rights and obligations under this Agreement.
                <br /> 2.4 Each Party confirms it is acting on its own behalf
                and not for the benefit of any other person.
                <br /> 2.5 The Licensee shall: (a) Notify the Licensor as soon
                as it becomes aware of any unauthorised use of the Product by
                any person; (b) Pay, for broadening the scope of the license(s)
                granted under this Agreement to cover the unauthorised use, an
                amount equal to the fees which the Licensor would have levied
                (in accordance with its normal commercial terms then current)
                had it licensed any such unauthorised use on the date when such
                use commended.
                <br /> 2.6 If the Licensee wishes to license the Product after
                the Evaluation Term, or for purposes other than those set forth
                in this Section, the Licensee may contact the Licensor to
                discuss a subscription that includes a production license. Any
                such license will be subject to a separate agreement.
              </p>
              <p>
                <b>3. LICENSE CONDITIONS AND RESTRICTIONS</b>
                <br />
                3.1 Unless expressly otherwise set forth in this Agreement, the
                Licensee will not: (a) modify, translate or create derivative
                works of the Product; (b) decompile, reverse engineer or reverse
                assemble any portion of the Product or attempt to discover any
                source code or underlying ideas or algorithms of the Product;
                (c) sell, assign, sublicense, rent, lease, loan, provide,
                distribute or otherwise transfer all or any portion of the
                Product (d) make, have made, reproduce or copy the Product; (e)
                remove or alter any trademark, logo, copyright or other
                proprietary notices associated with the Product; and (f) cause
                or permit any other party to do any of the foregoing.
                <br /> 3.2 The Licensee cannot use the Product, for purposes
                other than as specified in this Agreement.
                <br /> 3.3 The Licensee may permit its employees to use the
                Product, provided that the Licensee takes all necessary steps
                and imposes the necessary conditions to ensure that all
                employees using the Product do not commercialize or disclose the
                contents of it to any third person, or use it other than in
                accordance with the terms of this Agreement.
                <br /> 3.4 The Licensee will provide technological and security
                measures to ensure that the Product which the Licensee is
                responsible for, is physically and electronically secure from
                unauthorised use or access.
                <br /> 3.5 The Licensee shall not use the Product in any
                unlawful manner or in any manner that interferes with or
                disrupts the integrity or performance of the Product and its
                components or infringes on the rights of another party. The
                Licensee shall not modify, adapt or hack any protected
                (encrypted or compiled) parts of the Product, or otherwise
                attempt to gain unauthorized access to those parts or its
                related systems or networks. The Licensee undertakes not to
                promote any material that is unlawful, threatening, abusive,
                malicious, defamatory, false, materially inaccurate, or
                otherwise objectionable. The Licensee will not reproduce,
                publish, or distribute content in connection with the Product
                that infringes any third party’s trademark, copyright, patent,
                trade secret, publicity, privacy, or other personal or
                proprietary right. The Licensor offers no assurance that the
                Licensee use of the Product under the terms of the Agreement
                will not violate any law or regulation applicable to the
                Licensee.
                <br /> 3.6 The Licensee must only install the Product and make
                the Product available for use on systems owned, leased, or
                controlled by the Licensee in such a way that the Licensee can
                guarantee compliance with the terms of the Agreement. The
                Licensee assumes all responsibility for any and all use of the
                Product, including but not limited to content and media that is
                created, uploaded to, downloaded from, transmitted and edited
                using the Product. The Licensee is responsible for any accesses
                made to the Product.
                <br /> 3.7 With respect to any use of the Product, the Licensee
                shall keep the attribution and hyperlink to the Licensor and its
                Site intact.
                <br /> 3.8 The Licensee shall not obtain copies of the Product
                through any means other than directly through the Licensor’s
                Site or its Licensors. The Licensor does not guarantee that the
                Product will be available for download from the Site.
                <br /> 3.9 You shall not commit any act likely to result in the
                disrepute or harming of interests of the Licensor’s name or the
                name of its third party suppliers, whether through explicit act
                or omission.
                <br /> 3.10 The Licensee acknowledges that evaluation versions
                of the Product may have more limited functionality than
                commercial versions available for a fee. The Licensee
                acknowledges that the Licensor may discontinue the evaluation
                program at any time.
              </p>
              <p>
                <b>4. MAINTENANCE RELEASES, NEW RELEASES, UPDATES</b>
                <br />
                4.1 The Licensee is entitled to use new releases of and updates
                to the Product generally made available to its customers. Any
                new releases of and updates to the Product received by the
                Licensee and are entitled to use pursuant to this Section 1.4
                shall be included in the definition of &ldquo;Product&rdquo;
                hereunder and shall be governed by the terms of this Agreement
                unless such new release or update is accompanied by a separate
                license, in which case the terms of that license will govern the
                Product and/or such new release or update. The Licensee may
                obtain new releases of and/or updates to the Product only from
                the Licensor or other sources authorized by the Licensor.
              </p>
              <p>
                <b>5. OWNERSHIP AND INTELLECTUAL PROPERTY RIGHTS</b>
                <br />
                5.1 As between the Licensor and the Licensee and subject to the
                grants under this Agreement, the Licensor owns all right, title
                and interest in and to: (a) the Product (including, but not
                limited to, any modifications thereto or derivative works
                thereof); (b) all ideas, inventions, discoveries, improvements,
                information, creative works and any other works discovered,
                prepared or developed by the Licensor in the course of or
                resulting from the provision of any services under this
                Agreement; and (c) any and all Intellectual Property Rights
                embodied in the foregoing.
                <br /> 5.2 The Licensee acknowledges that all Intellectual
                Property Rights in the Product and any maintenance releases
                belong and shall belong to the Licensor, and the Licensee shall
                have no rights in or to the Product other than the right to use
                it in accordance with the terms of this Agreement.
              </p>
              <p>
                <b>6. CONSENT REGARDING DATA</b>
                <br />
                6.1 The Licensee acknowledges and accepts that the Licensor may
                collect, store and use Technical Data that is gathered during
                the Licensee’s use of the Product.
                <br /> 6.2 The Licensor may use the Technical Data to understand
                the way in which the Licensee uses the Product and to provide
                support to the Licensee in their use of the Product.
                <br /> 6.3 The Licensee may at any time disable the collection
                of Technical Data via the configuration settings of the Product
                or, alternatively, by advising the Licensor that they wish to
                disable the collection of Technical Data.
              </p>
              <p>
                <b> 7. LIMITATION OF LIABILITY</b>
                <br />
                7.1 Except as expressly stated in clause 7.2; (a) The Licensor
                shall not in any circumstances have any liability for any losses
                or damages which may be suffered by the Licensee (or any other
                person claiming under or through the Licensee), whether the same
                are suffered directly or indirectly or are immediate or
                consequential, and whether the same arise in contract, tort
                (including negligence) or otherwise, howsoever, which fall
                within any of the following categories; (i) special damage even
                if the Licensor was aware of the circumstances in which such
                special damage could arise; (ii) loss of profits; (iii) loss of
                anticipated savings; (iv) loss of business opportunity; (v) loss
                of goodwill; (vi) loss or corruption of data; provided that this
                clause 7.1(a) shall not prevent claims for loss or damage to the
                Licensee’s tangible property that fall within the terms of
                clause 7.1(b) or any other claims for direct financial loss that
                are not excluded by any of the categories (i) to (v) of this
                clause 7.1(a); (c) the Licensee agrees that, in entering into
                this licence, either it did not rely on any representations
                (whether written or oral) of any kind or of any person other
                than those expressly set out in this licence or (if it did rely
                on any representations, whether written or oral, not expressly
                set out in this licence) that it shall have no remedy in respect
                of such representations and (in either case) the Licensor shall
                have no liability in any circumstances otherwise than in
                accordance with the express terms of this licence.
                <br /> 7.2 The exclusions in clause 7.1 shall apply to the
                fullest extent permissible at law, but the Licensor does not
                exclude liability for: (a) death or personal injury caused by
                the negligence of the Licensor, its officers, employees,
                contractors or agents; (b) fraud or fraudulent
                misrepresentation; (c) breach of the obligations implied by
                section 12 of the Sale of Goods Act 1979 or section 2 of the
                Supply of Goods and Services Act 1982; or (d) any other
                liability which may not be excluded by law.
                <br /> 7.3 All dates supplied by the Licensor for the delivery
                of the Product [or the provision of Services] shall be treated
                as approximate only. The Licensor shall not in any circumstances
                be liable for any loss or damage arising from any reasonable
                delay in delivery beyond such approximate dates.
                <br /> 7.4 All references to &ldquo;the Licensor&rdquo; in this
                clause 7 shall, for the purposes of this clause only, be treated
                as including all employees, subcontractors and suppliers of the
                Licensor and its Affiliates, all of whom shall have the benefit
                of the exclusions and limitations of liability set out in this
                clause.
              </p>
              <p>
                <b>8. CONFIDENTIALITY</b>
                <br />
                8.1 Each Party shall, during the term of this licence and
                thereafter, keep confidential all, and shall not use for its own
                purposes (other than implementation of this licence) nor without
                the prior written consent of the other disclose to any third
                party (except its professional advisors or as may be required by
                any law or any legal or regulatory authority) any, information
                of a confidential nature (including trade secrets and
                information of commercial value) which may become known to such
                Party from the other Party and which relates to the other Party
                or any of its Affiliates, unless that information is public
                knowledge or already known to such Party at the time of
                disclosure, or subsequently becomes public knowledge other than
                by breach of this licence, or subsequently comes lawfully into
                the possession of such Party from a third party. Each Party
                shall use its reasonable endeavours to prevent the unauthorised
                disclosure of any such information.
                <br /> 8.2 Neither Party may use, disclose or make available to
                any third party the other Party’s Confidential Information,
                unless such use or disclosure is done in accordance with the
                terms of this Agreement.
                <br /> 8.3 This clause 8 will survive termination of this
                Agreement.
              </p>
              <p>
                <b> 9. WARRANTY DISCLAIMER</b>
                <br />
                9.1 To the extent permitted by law, the Licensor will in no way
                be liable to the Licensee or any third party for any loss or
                damage, however caused (including through negligence) which may
                be directly or indirectly suffered in connection with any use of
                the Product.
                <br /> 9.2 The Product is provided by the Licensor on an
                &ldquo;as is&rdquo; or &ldquo;as available&rdquo; basis without
                any representations, warranties, covenants or conditions of any
                kind.
                <br /> 9.3 The Licensor will not be held liable by the Licensee
                in any way, for any loss, damage or injury suffered by the
                Licensee or by any other person related to any use of the
                Product or any part thereof.
                <br /> 9.4 Notwithstanding anything contained in this Agreement,
                in no event shall the Licensor be liable for any claims, damages
                or loss which may arise from the modification, combination,
                operation or use of the Product with the Licensee’s computer
                programs.
                <br /> 9.5 The Licensor does not warrant that the Product will
                function in any environment.
                <br /> 9.6 The Licensee acknowledges that: (a) the Product has
                not been prepared to meet any specific requirements of any
                party, including any requirements of Licensee; and (b) it is
                therefore the responsibility of the Licensee to ensure that the
                Product meets its own individual requirements.
                <br /> 9.7 To the extent permitted by law, no express or implied
                warranty, term, condition or undertaking is given or assumed by
                the Licensor, including any implied warranty of merchantability
                or fitness for a particular purpose.
                <br /> 9.8 The Licensor and its suppliers do not warrant that
                any of the products will be free from all bugs, errors, or
                omissions.
                <br /> 9.9 The Licensor does not warrant uninterrupted or
                error-free operation of the Product or that it will correct all
                errors. The Licensee is responsible for the results obtained
                from the use of the Product.
                <br /> 9.10 The Licensee acknowledges that use of the Product
                has the risk of human and machine errors, omissions, delays and
                losses and expressly assumes all risk with respect thereto.
              </p>
              <p>
                <b>10. INDEMNIFICATION</b>
                <br />
                10.1 The Licensee will indemnify, defend and hold the Licensor
                and its directors, officers, employees, suppliers, consultants,
                contractors, and agents (&ldquo;Licensor Indemnitees&rdquo;)
                harmless from and against any and all actual or threatened
                suits, actions, proceedings (at law or in equity), claims
                (groundless or otherwise), damages, payments, deficiencies,
                fines, judgments, settlements, liabilities, losses, costs and
                expenses (including, but not limited to, reasonable attorney
                fees, costs, penalties, interest and disbursements) resulting
                from any claim (including third-party claims), suit, action, or
                proceeding against any Licensor Indemnitees, whether successful
                or not, caused by, arising out of, resulting from, attributable
                to or in any way incidental to: (a) any breach of this Agreement
                (including, but not limited to, any breach of any of Licensee’s
                representations, warranties or covenants); or (b) the negligence
                or willful misconduct of the Licensee.
                <br /> 10.2 This clause 10 will survive termination of this
                Agreement.
              </p>
              <p>
                <b>11. GOVERNING LAW</b>
                <br />
                11.1 This Agreement and any dispute or claim arising out of or
                in connection with it or its subject matter or formation
                (including non-contractual disputes or claims) shall be governed
                by and construed in accordance with the law of England and
                Wales.
                <br /> 11.2 The Parties irrevocably agree that the courts of
                England and Wales shall have exclusive jurisdiction to settle
                any dispute or claim that arises out of or in connection with
                this Agreement or its subject matter or formation (including
                non-contractual disputes or claims).
              </p>
              <p>
                <b>12. TERMINATION</b>
                <br />
                12.1 This Agreement and the license granted herein commences
                upon the Commencement Date and is granted for the Evaluation
                Term, unless otherwise terminated by Licensor in the event of
                any of the following: (a) if the Licensee is in breach of any
                term of this Agreement and has not corrected such breach to
                Licensor’s reasonable satisfaction within 15 days of Licensor’s
                notice of the same; (b) if the Licensee becomes insolvent, or
                institutes (or there is instituted against it) proceedings in
                bankruptcy, insolvency, reorganisation or dissolution, or makes
                an assignment for the benefit of creditors; or (c) the Licensee
                is in breach of clause 7,8,10 or 11 of this Agreement.
                <br /> 12.2 Termination under this clause shall not affect any
                other rights or remedies Licensor may have.
              </p>
              <p>
                <b>13. THIRD PARTY SOFTWARE</b>
                <br />
                13.1 The Product may include software or other code distributed
                subject to licenses from third-party suppliers (&ldquo;Third
                Party Software&rdquo;). The Licensee accepts and agrees to the
                terms of such third-party licenses applicable to the Third Party
                Software and acknowledges that such third-party suppliers
                disclaim and make no representation or warranty with respect to
                the Product or any portion thereof and assume no liability for
                any claim that may arise with respect to the Product. Third
                Party Software licenses are set forth at:
                https://lenses.io/third-party-software
              </p>
              <p>
                <b>14. ASSIGNMENT</b>
                <br />
                14.1 The Licensee shall not assign any rights of this Agreement,
                without the prior written consent of the Licensor.
              </p>
              <p>
                <b>15. NO PARTNERSHIP OR AGENCY</b>
                <br />
                15.1 Nothing in this Agreement is intended to, or shall be
                deemed to establish any partnership or joint venture between any
                of the Parties, or constitute any Party the agent of another
                Party, or authorise any Party to make or enter into any
                commitments for or on behalf of any other Party.
              </p>
              <p>
                <b>16. SEVERANCE</b>
                <br />
                16.1 If any provision or part-provision of this Agreement is or
                becomes invalid, illegal or unenforceable, it shall be deemed
                modified to the minimum extent necessary to make it valid, legal
                and enforceable. If such modification is not possible, the
                relevant provision or part-provision shall be deemed deleted.
                Any modification to or deletion of a provision or part-provision
                under this clause 17.1 shall not affect the validity and
                enforceability of the rest of this Agreement.
                <br /> 16.2 If any provision or part-provision of this Agreement
                is invalid, illegal or unenforceable, the Parties shall
                negotiate in good faith to amend such provision so that, as
                amended, it is legal, valid and enforceable, and, to the
                greatest extent possible, achieves the intended commercial
                result of the original provision.
              </p>
              <p>
                <b>17. WAIVER</b>
                <br />
                17.1 No failure or delay by a Party to exercise any right or
                remedy provided under this Agreement or by law shall constitute
                a waiver of that or any other right or remedy, nor shall it
                prevent or restrict the further exercise of that or any other
                right or remedy. No single or partial exercise of such right or
                remedy shall prevent or restrict the further exercise of that or
                any other right or remedy.
              </p>
              <p>
                <b>18. NOTICES</b>
                <br />
                18.1 The Licensee agrees that the Licensor may provide the
                Licensee with notices, including without limitation those
                regarding changes to the Agreement, by e-mail, regular mail, or
                messages or postings through the Product.
              </p>
              <p>
                <b>19. SUPPORT</b>
                <br />
                19.1 Additional services such as Product support and maintenance
                or consulting shall be charged in addition to a fee specified by
                the Licensor. The Licensor support services are not available
                for the evaluation use of Product. The Licensor may, but is not
                required to, make patches, updates and upgrades to the Product
                available from time to time. Installation and use of such
                releases are governed by the terms of this Agreement
                <br /> 19.2 The Licensor shall not be obliged to make
                modifications or provide support in relation to the
                Licensee&apos;s computer hardware, operating system software, or
                third party application software or any data feeds or external
                data.
              </p>
              <p>
                <b>20. TRADEMARKS AND PUBLICITY</b>
                <br />
                20.1 The Licensee shall not use Licensor’s name or trademarks,
                unless written permission is obtained from Licensor or otherwise
                set out in the Agreement.
                <br /> 20.2 The Licensee shall not commit any act where the
                result of which is the likely disrepute or harming of interests
                of the Licensor’s name or the name of its third party vendors,
                whether through explicit act or omission.
                <br /> 20.3 The Licensee authorizes the Licensor to use
                Licensee’s name and trademarks in Licensor’s promotional
                materials and for publicity purposes. The Licensee can opt-out
                at any time by writing to: info@lenses.io.
              </p>
              <p>
                <b>21 ENTIRE AGREEMENT</b>
                <br />
                21.1 The Agreement constitutes the entire agreement between the
                Licensee and the Licensor and govern the Licensee’s use of the
                Product, superseding any prior agreements between the Licensee
                and the Licensor for the use of the Product. The Licensee also
                may be subject to additional terms and conditions that may apply
                when the Licensee use or purchase certain other of the
                Licensor’s services, affiliate services, third-party content, or
                third-party software.
              </p>
              <p>Updated Nov 2017</p>
            </div>
          </div>
        </div>
      </section>

      <section className="se-section">
        <div className="container-1">
          <div className="row"></div>
          <div className="row well">
            <form
              className="col-md-12"
              action="https://milou.landoop.com/api/lenses-trial"
              method="POST"
              id="form"
            >
              {/* <!-- TODO: The macOS and Windows versions are deprecated. Below we hide the choice so it can work with
                     current milou versions. The next milou update will accept an empty value for OS, so we can
                     remove the code completely. --> */}
              <div className="form-group col-md-12" style={{ display: 'none' }}>
                <label style={{ marginBottom: '5px' }} htmlFor="os">
                  Choose Operating System
                </label>
                <select
                  className="form-control"
                  style={{
                    width: '30%',
                    minWidth: '300px',
                    marginBottom: '10px',
                  }}
                  name="os"
                  id="os"
                  defaultValue="linux"
                >
                  <option value="linux">Linux/amd64</option>
                  <option value="mac">macOS</option>
                  <option value="windows">Windows/amd64</option>
                </select>
              </div>

              <div className="form-group col-md-12">
                <input
                  type="text"
                  id="dl_id"
                  name="dl_id"
                  required
                  style={{ display: 'none' }}
                />

                <label style={{ paddingLeft: '5px' }} htmlFor="accept_eula">
                  <input
                    type="checkbox"
                    name="accept_eula"
                    className="form-control"
                    id="accept_eula"
                    required={true}
                    style={{
                      border: '0',
                      boxShadow: 'none',
                      width: 'auto',
                      display: 'inline-block',
                      height: 'auto',
                      margin: '0px 5px 0 5px',
                      textAlign: 'right',
                      paddingLeft: '5px',
                    }}
                  />
                  I accept the Software Evaluation License Agreement
                </label>
              </div>
              <div className="col-md-12 mt10 mb20">
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: '#e45453',
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  download
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default lensesDownload
